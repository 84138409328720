export const PROJECTS = [
  {
    id: '0',
    title: 'LyveCom',
    description:
      'A platform to turn static website into a video commerce platform. Streamlined user experience lets shoppers checkout with 1-click, without ever leaving your video. It’s like Tiktok or Instagram Shopping – directly on your Shopify store.',
    image: {
      src: 'https://images.squarespace-cdn.com/content/v1/5f42ce624278dc3363ba9ec2/98b7eadc-a4ed-4a1b-92e6-0760687adb58/brandd.png',
    },
    techs: [
      'React',
      'Mux',
      'i18next',
      'Semnatic UI',
      'django'
    ],
    type: "Web",
    mediaType: "video",
    link:'https://lyvecom.com/',
  },
  {
    id: '1',
    title: 'Zaya Care',
    description:
      'A platform to find and book maternal health specialists.',
    image: {
      src: 'https://lpsb8c.p3cdn1.secureserver.net/wp-content/uploads/2022/07/mock-1-1024x731-1-768x548.webp',
    },
    techs: [
      'Next Js',
      'React Js',
      'TypeScript',
      'Elastic Search',
      'GraphQL',
      'Material UI',
      'Elastic UI',
      'Node Js'
    ],
    link:'https://zayacare.com/',
    type: "Web",
  },
  {
    id: '2',
    title: 'Santa Fe Furniture',
    description:
      'An e-commerce site to buy all kinds of furniture.',
    image: {
      src: 'https://codesyconsulting.com/wp-content/uploads/2022/07/santafefurniturestore.jpg',
    },
    techs: [
      'Wordpress',
      'PHP',
      'Laravel',
      'Elevantor',
      'Big Commerce',
    ],
    linl:"https://www.santafefurniturestore.com/",
    type: "E-Commerce",
  },
  {
    id: '3',
    title: 'Accure',
    description:
      "A react native app with animations made with react-native-reanimated. The app is based on the Outfitr - Fashion UI Kit. Inspired by William Candillon's youtube series.",
    image: {
      src: 'https://lpsb8c.p3cdn1.secureserver.net/wp-content/uploads/2022/07/wpMockup-2-768x548.webp',
    },
    techs: [
      'Vue 3',
      'TypeScript',
      'Tailwind CSS',
      'VueX',
      'Vite',
    ],
    type: "Web",
  },
  {
    id: '4',
    title: 'Vivid Creative',
    description:
      'Vivid is a creative marketing agency that partners with ambitious businesses and organisations who want their brand to burn brighter.',
    image: {
      src: 'https://lpsb8c.p3cdn1.secureserver.net/wp-content/uploads/2022/07/vividcreative.jpg?time=1674025479',
    },
    techs: [
      'Vanilla JavaScript',
      'Animate Js',
      'Chart Js',
      'SVG.js',
      'Snap.svg'
    ],
    link:"https://www.vividcreative.com/",
    type: "Web",
  },
  // {
  //   id: '5',
  //   title: 'Voxel',
  //   description:
  //     'A MERN app to let users share their experience of places along with Details. It is user friendly and can be useful to many others.',
  //   image: {
  //     src: 'https://lpsb8c.p3cdn1.secureserver.net/wp-content/uploads/2022/07/Voxel-768x357.png',
  //   },

  //   type: "Mobile",
  // },
  {
    id: '6',
    title: 'Catholic Benefits Association',
    description:
      'A Mobile eCommerce application built with React Native that includes cart functionality, push notifications, user authentication and user orders',
    image: {
      src: 'https://lpsb8c.p3cdn1.secureserver.net/wp-content/uploads/2022/07/catholicbenefitsassociation.jpg?time=1674025479',
    },
    techs: [
      'WordPress',
      'Laravel',
      'LAMP Stack',
    ],
    type: "Web",
  },
  {
    id: '7',
    title: 'Oxidane',
    description:
      'A Mobile application for sharing places that people visit from all over the world. Uses React Native, TypeScript, MobX and Native Device Features',
    image: {
      src: 'https://lpsb8c.p3cdn1.secureserver.net/wp-content/uploads/2022/07/oxidane-768x351.png',
    },
    techs: [
      'React Native',
      'TypeScript',
      'Redux',
      'React Query',
    ],
    type: 'Mobile',
  },
  {
    id: '8',
    title: 'Appsphalt',
    description:
      'Appsphalt provides the most comprehensive commercial and residential asphalt paving and maintenance services in the industry',
    image: {
      src: 'https://lpsb8c.p3cdn1.secureserver.net/wp-content/uploads/2022/07/Appsphalt.jpg?time=1674025479',
    },
    techs: [
      'React Native',
      'TypeScript',
      'Redux',
      'React Query',
    ],
    type: 'Mobile',
  },
  {
    id: '9',
    title: 'Inpay',
    description:
      'IN Pay (short for Inbank Pay) is a new generation payment app and card, which gives you 2% cashback',
    image: {
      src: 'https://lpsb8c.p3cdn1.secureserver.net/wp-content/uploads/2022/07/inpay-1024x522-1-768x392.png',
    },
    techs: [
      'React Native',
      'TypeScript',
      'Redux',
      'Stripe'
    ],
    type: 'Web',
  },
  {
    id: '10',
    title: 'Willow',
    description:
      'Child care app',
    image: {
      src: 'https://lpsb8c.p3cdn1.secureserver.net/wp-content/uploads/2022/07/willow.jpg?time=1674025479',
    },
    techs: [
      'React Native',
      'TypeScript',
      'Redux',
      'React Query',
    ],
    type: 'Mobile',
  },
];
